import { useStore } from "vuex";
import asyncOperations from "@/client/extensions/composition/asyncOperations.js";
import { ref, computed, reactive, watchEffect, watch } from "vue";

let extractStoreFromArgument = (storeArgument) => {
  let store = storeArgument;
  if (!store) {
    store = useStore();
  }

  if (!store) {
    error(
      "ERROR: useCarts requires access to store. Either call it in a setup context, or provide the store as a second argument. Troubles will most likely follow."
    );
  }

  return store;
};

export default (props, options = { store: false, asyncOps: false }) => {
  let store = extractStoreFromArgument(options.store);
  let { asyncOps } = options.asyncOps ? options.asyncOps : asyncOperations(props, store);

  let cartReadyPromise; // set later

  let test = () => "test";

  let carts = reactive({});

  let cartUuids = computed(() => {
    return Object.keys(carts);
  });

  let extractCartUuid = (input) => {
    if (typeof input === "string") {
      return input;
    }

    if (input && typeof input === "object" && input.uuid) {
      return input.uuid;
    }
    return false;
  };

  // when we change our cart list - sync to store
  watch(
    carts,
    (newVal) => {
      store.commit("carts/setCartUuids", cartUuids.value);
    },
    { deep: true }
  );

  let defaultCartUuid = ref(null);

  const refreshCart = async (cartOrUuid) => {
    let targetId = extractCartUuid(cartOrUuid);

    if (!targetId) {
      let cart = await getCart();
      targetId = cart.uuid;
    }

    await loadCartByUuid(targetId);

    return carts[targetId];
  };

  const reloadCart = async (cartOrUuid) => {
    return refreshCart(cartOrUuid);
  };

  const loadCartByUuid = async (cartOrUuid) => {
    const uuid = extractCartUuid(cartOrUuid);
    let loadResult = await asyncOps.asyncCall(
      "ecommerce/cart/" + uuid,
      {},
      { method: "get" }
    );
    if (loadResult.hasError) {
      notice(
        "error while loading cart. The cart may have been deleted on the server",
        loadResult
      );
      return false;
    }

    addCart(loadResult.data.item);
    return carts[loadResult.data.item.uuid];
  };

  /**
   * Get all the carts in memory
   * @returns {UnwrapNestedRefs<{}>}
   */
  let getCarts = async () => {
    await cartReadyPromise;
    return carts;
  };

  /**
   * Get a cart by UUID, from the carts in memory
   * @param uuid
   * @returns {*|boolean}
   */
  let getCart = async (uuid = undefined) => {
    await cartReadyPromise;
    if (!uuid) {
      return getDefaultCart();
    }

    if (!carts[uuid]) {
      return getDefaultCart();
    }
    if (carts[uuid]) {
      if (carts[uuid].isProxy) {
        return carts[uuid];
      }
      return getCartProxy(carts[uuid]);
    }

    return false;
  };

  /**
   * Get the default cart
   * @returns {boolean|*|boolean}
   */
  let getDefaultCart = async () => {
    await cartReadyPromise;
    // if default is set - get it
    if (defaultCartUuid.value) {
      console.log(defaultCartUuid);
      return getCart(defaultCartUuid.value);
    }

    await lazyCreateDefaultCart();
    return getCart(defaultCartUuid.value);
  };

  /**
   * Set the default cart by ID or UUID
   * @param cartOrUuid
   */
  let setDefaultCart = (cartOrUuid) => {
    let uuid = extractCartUuid(cartOrUuid);

    // local state
    defaultCartUuid.value = uuid;

    // global state
    store.commit("carts/setDefaultCartUuid", uuid);
    return true;
  };

  /**
   * Given a cart or cart ID set is as the default, if no default is available
   * @param cartOrId
   */
  let setDefaultCartIfNotSet = (cartOrId) => {
    if (!defaultCartUuid.value) {
      setDefaultCart(cartOrId);
    }
  };

  /**
   * Wrap a cart object with a proxy, so it can have methods
   * @param cart
   * @returns {*}
   */
  let getCartProxy = (cart) => {
    const target = cart;
    let proxy;
    if (cart.isSaffronCartProxy === true) {
      return cart;
    }

    const addCartItem = async (arg) => {
      const newCart = await addItem(arg, cart);
      target.CartItems = newCart.CartItems;
      return proxy;
    };

    const removeCartItem = async (arg) => {
      return removeItem(arg, cart);
    };

    const updateCartItem = async (item, itemUpdates) => {
      return updateItem(item, itemUpdates, cart);
    };

    const proxyReloadCart = async (arg) => {
      return reloadCart(arg, cart);
    };

    const emptyCartInternal = async () => {
      return emptyCart(cart);
    };

    let comfortMap = {
      cartItems: "CartItems",
      items: "CartItems",
    };
    const handler = {
      get(target, prop, receiver) {
        if (prop === 'isProxy') {
          return true;
        }
        if (prop === "addItem") {
          return addCartItem;
        }

        if (prop === "updateItem") {
          return updateCartItem;
        }

        if (prop === "removeItem") {
          return removeCartItem;
        }

        if (prop === "emptyCart" || prop === "empty") {
          return emptyCartInternal;
        }

        if (prop === "reload" || prop === "refresh") {
          return proxyReloadCart;
        }

        if (prop === "isSaffronCartProxy") {
          return true;
        }

        if (prop === "isEmpty") {
          return function () {
            if (!target.CartItems) {
              return true;
            }

            return target.CartItems.length < 1;
          };
        }
        if (comfortMap[prop]) {
          return target[comfortMap[prop]];
        }

        return target[prop];
      },
    };

    proxy = new Proxy(target, handler);
    return proxy;
  };

  /**
   * Add a cart to the state
   * Overrides cart if exists
   * @param cart
   */
  let addCart = (cart) => {
    carts[cart.uuid] = getCartProxy(cart);
  };

  /**
   * Create a cart from the server.
   * Will add it to the carts in memory.
   * Will set it as the default cart if a default cart is not set.
   * @returns {Promise<boolean|*>}
   */
  let createCart = async () => {
    // we would like to wait. But this is called by the generator of the cartReadyPromise, resulting in an endless wait
    // await Promise.race([cartReadyPromise, utilities.wait(1000)]);
    let res = await asyncOps.asyncCall("ecommerce/cart", {}, { method: "post" });
    if (res.hasError) {
      return false;
    }

    await loadCartByUuid(res.data.item.uuid);
    setDefaultCartIfNotSet(carts[res.data.item.uuid]);
    return carts[res.data.item.uuid];
  };

  let enforceCartItemUpdateIntegrity = (arg) => {
    if (!arg || typeof arg !== "object") {
      arg = {};
    }

    delete arg.uuid;
    delete arg.translations;
    delete arg.id;
    delete arg.order;
    delete arg.state;

    return arg;
  };

  let extractCartItemFromArgument = (arg) => {
    if (!arg || typeof arg !== "object") {
      return false;
    }
    let quantity = arg.quantity ? arg.quantity : 1;
    quantity = parseInt(quantity);

    // extract ProductId
    let ProductId = false;
    if (arg?.ProductId) {
      ProductId = arg.ProductId;
    }

    if (arg?.product && arg?.product?.ProductId) {
      ProductId = arg.product.ProductId;
    }

    if (!ProductId) {
      return false;
    }

    if (!arg.productConfig || typeof arg.productConfig !== "object") {
      return false;
    }

    // validate product configuration

    return {
      ...arg,
      ProductId,
      quantity,
    };
  };

  let addItem = async (arg, cartOrCartId) => {
    let item = extractCartItemFromArgument(arg);

    if (!item) {
      return false;
    }

    // extract cart
    let cartUuid = extractCartUuid(cartOrCartId);

    if (!cartUuid) {
      return false;
    }

    // send request to server
    let cart = await getCart(cartUuid);
    let url = `ecommerce/cart/${cartUuid}/item`;
    let serverAddResult = await asyncOps.asyncCall(url, item, { method: "post" });

    if (serverAddResult.hasError) {
      notice("useCarts.addItem - server rejected the request", serverAddResult);
      return false;
    }

    // refresh the cart
    let refreshedCart = await refreshCart();

    // update the old object, references may be still available
    cart.CartItems = refreshedCart.CartItems;

    return cart;
  };

  let removeItem = async (itemOrItemUuid, optionalCartOrCartUuid) => {
    let itemUuid = itemOrItemUuid;
    let targetCartUuid = extractCartUuid(optionalCartOrCartUuid);

    if (typeof itemUuid === "object" && itemUuid.hasOwnProperty("uuid")) {
      itemUuid = itemUuid.uuid;
    }

    if (typeof itemUuid !== "string") {
      warn("carts.removeItem - argument must be an item or an item UUID", itemOrItemUuid);
      return false;
    }

    if (!targetCartUuid) {
      targetCartUuid = await getDefaultCart().uuid;
    }

    let url = `ecommerce/cart/${targetCartUuid}/item`;
    let cart = await getCart(targetCartUuid);

    let serverResult = await asyncOps.asyncCall(
      url,
      { uuids: [itemUuid] },
      { method: "delete" }
    );
    if (serverResult.hasError) {
      notice("carts.removeItem - server rejected the delete request", serverResult);
      return false;
    }

    // refresh the cart
    let refreshedCart = await refreshCart();

    // update the old object, references may be still available
    cart.CartItems = refreshedCart.CartItems;
    return true;
  };

  let updateItem = async (item, itemUpdates, cartOrCartId) => {
    let updates = enforceCartItemUpdateIntegrity(itemUpdates);

    if (!updates) {
      warn(
        "useCarts.update item - unable to extract update data from argument",
        itemUpdates
      );
      return false;
    }

    // extract cart
    let cartUuid = extractCartUuid(cartOrCartId);

    if (!cartUuid) {
      return false;
    }

    // send request to server
    // TODO: server side and test for this
    let cart = await getCart(cartUuid);
    let url = `ecommerce/cart/${cartUuid}/item/${item.uuid}`;
    let serverAddResult = await asyncOps.asyncCall(url, updates, { method: "patch" });

    if (serverAddResult.hasError) {
      notice("useCarts.updateItem - server rejected the request", serverAddResult);
      return false;
    }

    // refresh the cart
    let refreshedCart = await refreshCart();

    // update the old object, references may be still available
    cart.CartItems = refreshedCart.CartItems;
    return true;
  };

  let emptyCart = async (optionalCartOrCartUuid) => {
    let targetCartUuid = extractCartUuid(optionalCartOrCartUuid);

    if (!targetCartUuid) {
      targetCartUuid = await getDefaultCart().uuid;
    }

    let cart = carts[targetCartUuid];
    let url = `ecommerce/cart/${targetCartUuid}/item`;
    if (!cart.CartItems) {
      cart.CartItems = [];
    }
    let itemUuids = cart.CartItems.map((item) => item.uuid);
    let serverResult = await asyncOps.asyncCall(
      url,
      { uuids: itemUuids },
      { method: "delete" }
    );
    if (serverResult.hasError) {
      notice("carts.emptyCart - server rejected the delete request", serverResult);
      return false;
    }

    // refresh the cart
    await refreshCart();
    // update the old object, references may be still available
    cart.CartItems = [];
    return true;
  };

  const lazyCreateDefaultCart = async () => {
    // if we have a default cart we are all set
    if (defaultCartUuid.value) {
      return true;
    }

    // check if store has default cart. if so - load it.
    let defaultStoreCartUuid = store.getters["carts/defaultCartUuid"];

    if (defaultStoreCartUuid && typeof defaultStoreCartUuid === "string") {
      let cart = await loadCartByUuid(defaultStoreCartUuid);
      if (cart) {
        setDefaultCart(cart);
        return true;
      } else {
        let cart = await createCart();
        setDefaultCart(cart);
        return true;
      }
    }

    // no? than lets try and load the first of our known carts
    let allKnownCartUuids = store.getters["carts/cartUuids"];

    if (Array.isArray(allKnownCartUuids) && !allKnownCartUuids.length < 1) {
      let targetUuid = allKnownCartUuids[0];

      let cart = await loadCartByUuid(targetUuid);
      if (cart) {
        setDefaultCart(cart);
        return true;
      }
    }

    // nothing still?  create a new cart
    let cart;
    try {
      cart = await createCart();
    } catch (e) {
      warn("failed to create default cart");
    }

    setDefaultCart(cart);
    return true;
  };

  cartReadyPromise = lazyCreateDefaultCart().catch((e) => {
    warn("useCarts ready promise rejectd", { e });
  });

  const createNewDefaultCart = async () => {
    let newCart = await createCart();
    setDefaultCart(newCart);
    return newCart;
  };

  const forgetCart = (cartOrCartUuid) => {
    console.log("forget", cartOrCartUuid);
    let target = cartOrCartUuid;
    if (typeof cartOrCartUuid === "object" && cartOrCartUuid) {
      target = cartOrCartUuid.uuid;
    }
    // delete from local state
    delete carts[target];
    store.commit("carts/forgetCart", target);

    // delete from store
  };
  return {
    isReady: cartReadyPromise,
    test,
    createCart,
    loadCartByUuid,
    getCart,
    setDefaultCart,
    switchCart: setDefaultCart,
    forgetCart: forgetCart,
    deleteCart: forgetCart,
    removeCart: forgetCart,
    addItem,
    refreshCart,
    reloadCart,
    emptyCart,
    createNewDefaultCart,
  };
};
